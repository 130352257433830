<template>
  <div>
    <h5>
      Select Company
    </h5>
    <div
      v-if="listView"
      id="company-select-list"
    >
      <b-list-group>
        <b-list-group-item
          v-for="company in visibleCompanies"
          :key="company.id"
          :class="{ active: selected === company.id }"
          @click="setCompany(company.id)"
        >
          {{ company.name }}
        </b-list-group-item>
      </b-list-group>
    </div>
    <div
      v-if="!listView"
      id="company-select-dropdown"
    >
      <b-form-select
        v-model="selected"
        :options="visibleCompanies"
        value-field="id"
        text-field="name"
        @change="setCompany(selected)"
      >
        <!-- <b-form-select-option :value="null">Select an option</b-form-select-option> -->
      </b-form-select>
    </div>
  </div>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BFormSelect,
  // BFormSelectOption,
} from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BFormSelect,
    // BFormSelectOption,
  },
  props: {
    listView: {
      type: Boolean,
      default: false,
    },
    loadDefault: {
      type: Boolean,
      default: false,
    },
    loadCompany: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    visibleCompanies() {
      return this.$store.getters['campaigns/getAllCompanies']
    },
  },
  created() {
    this.$store.dispatch('campaigns/fetchAllCompanies')
      .then(() => {
        // timeout delay to give time for getters
        if (this.loadDefault) {
          this.loadDefaultCompany()
        } else if (this.loadCompany) {
          this.loadSetCompany()
        }
      })
  },
  methods: {
    loadDefaultCompany() {
      if (this.visibleCompanies) {
        this.selectDefaultCompany()
      } else {
        setTimeout(() => this.loadDefaultCompany(), 100)
      }
    },
    loadSetCompany() {
      if (this.visibleCompanies) {
        this.setCompany(this.loadCompany)
      } else {
        setTimeout(() => this.loadSetCompany(), 100)
      }
    },
    setCompany(companyId) {
      this.selected = companyId
      this.$emit('setCompany', companyId)
    },
    selectDefaultCompany() {
      if (this.visibleCompanies && this.visibleCompanies[0]) {
        const defaultCompanyId = this.visibleCompanies[0].id // select first company
        this.selected = defaultCompanyId
        this.$emit('setCompany', defaultCompanyId)
      }
    },
  },
}
</script>
